<template>

  <div class="basic">

    <v-row no-gutters  :class="{'pt-5': !mobile}"  align="center" justify="center" >
      <v-col cols="12">
        <v-card flat class="pa-4 ma-3">
          <v-row dense>
            <v-col cols="12" class="caption">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header class="subtitle-2">범례</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-icon color="red">looks_one</v-icon> A급 : 큰기업 도축장, HACCP, 냉장, 무항생제
                    </v-row>
                    <v-row dense>
                      <v-icon color="yellow">looks_two</v-icon> A-급 : 큰기업 도축장, HACCP, 급속냉동, 무항생제
                    </v-row>
                    <v-row dense>
                      <v-icon color="green">looks_3</v-icon> B급 : 큰기업 도축장, HACCP, 냉장
                    </v-row>
                    <v-row dense>
                      <v-icon color="blue">looks_4</v-icon> B-급 : 큰기업 도축장, HACCP, 급속냉동
                    </v-row>
                    <v-row dense>
                      <v-icon color="violett">looks_5</v-icon> C급 : 큰기업 도축장, HACCP, 냉동
                    </v-row>
                    <v-row dense>
                      <v-icon color="grey">looks_6</v-icon> D급 : 도축장 미표기, HACCP
                    </v-row>
                    <v-row dense>
                      <v-icon color="grey">looks_7</v-icon> E급 : 도축장 미표기
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-row>
            <v-col cols="12" >
              <v-container fluid v-if="loading">
                <v-row dense>
                  <v-col cols="12">
                    <v-row class="align-center justify-center">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
              <v-row dense>
                <v-col md="6" cols="12" v-for="(item, id) in list" :key="id">
                  <v-card outlined class="subtitle-2 pa-4">
                    <v-row dense>
                      <v-col cols="10">
                        <v-icon v-if="item.grade == 1" color="red">looks_one</v-icon>
                        <v-icon v-if="item.grade == 2" color="yellow">looks_two</v-icon>
                        <v-icon v-if="item.grade == 3" color="green">looks_3</v-icon>
                        <v-icon v-if="item.grade == 4" color="blue">looks_4</v-icon>
                        <v-icon v-if="item.grade == 5" color="violett">looks_5</v-icon>
                        <v-icon v-if="item.grade == 6" color="grey">looks_6</v-icon>
                        <v-icon v-if="item.grade == 7" color="grey">looks_7</v-icon>
                        <v-btn outlined color="primary" v-if="item.frozen == 0" xSmall >냉동</v-btn>
                        <v-btn outlined color="warning" v-if="item.frozen == 1" xSmall >냉장</v-btn>
                        {{ item.name }}
                      </v-col>
                      <v-col cols="2" class="ma-0 pa-0">
                        <v-btn color="primary" small text :href="item.link" target="_blank">
                          상품보기
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" >
                        <v-row dense  justify="center" class="mt-1">
                          <v-col>
                            가격: {{ item.price.format() }} ( 100g당: {{ item.price100.format() }} )
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" >
                        <v-row dense  justify="center" class="mt-1">
                          <v-col>
                            <span >배송비: {{ item.shipment }}</span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <div v-html="item.comment"></div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-row>
            <v-col cols="12" class="body-2">
              해당 링크를 통해 구매시 동면중에게 도움이 됩니다.
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import '../../common/common.js';

import { mapGetters } from 'vuex'

export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      loading: true,
      list: [],
      headers: [
        {
          text: '제품',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: '등급', value: 'grade', sortable: false, },
        { text: '냉동', value: 'frozen', sortable: false, },
        { text: '가격', value: 'price', sortable: false, },
        { text: '단가', value: 'price100', sortable: false, },
        { text: '배송료', value: 'shipment', sortable: false, },
        { text: '구매', value: 'link', sortable: false, },
        { text: '코멘트', value: 'comment', sortable: false, },
      ],
      grades: [
        { name: 'A',  grade: 1 },
        { name: 'A-', grade: 2 },
        { name: 'B',  grade: 3 },
        { name: 'B-', grade: 4 },
        { name: 'C',  grade: 5 },
        { name: 'D',  grade: 6 },
        { name: 'E',  grade: 7 },
      ],
      frozens: [
        { name: '냉장', frozen: 1 },
        { name: '냉동', frozen: 2 },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      lvl: "lvl",
      mobile: "mobile",

    })
  },
  created: async function() {
    this.loading = true;
  },
  mounted: async function() {
    if ( firebase.auth().currentUser ) {
      // 이전 정보가 있는지 확인
      const id_token = await firebase.auth().currentUser.getIdToken();
      const chickens = await axios.get(
        api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/chicken/', {
        params: { email: this.user.data.email },
        headers: {'id_token': id_token},
      });

      if (chickens.data.length>0) {
        this.list = chickens.data;
      }

      this.loading = false;
    }
  },
  methods: {
  }
}
</script>

<style scoped>

.v-expansion-panel::before {
  box-shadow: none;
  -webkit-box-shadow: none;
}
</style>
