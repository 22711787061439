<template>
  <div class="basic">

    <v-row no-gutters  :class="{'pt-2': !mobile}"  align="center" justify="center" >
      <v-col cols="12">
        <v-card flat class="pa-4 ma-3">
          <v-row dense>
            <v-col cols="12" class="caption">
              <v-icon color="green">looks_one</v-icon> A급
              <v-icon color="blue">looks_two</v-icon> B급
              <v-icon color="orange">local_hospital</v-icon> 번외 성분
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">

          <v-row dense>
            <v-col cols="12">
              <v-tabs v-model="supplement_tab" color="light-green darken-4">
                <v-tabs-slider color="light-green darken-1"></v-tabs-slider>
                <v-tab v-for="(category) in categories" :key="category.category">
                  {{ category.name }}
                </v-tab>

                <v-tabs-items touchless v-model="supplement_tab">
                  <v-tab-item
                    v-for="(category) in categories"
                    :key="category.category"
                    :transition="false"
                    :reverse-transition="false">

                    <v-row dense>

                      <v-col md="6" cols="12" v-for="(each) in list[category.category]" :key="each._id">

                        <v-card outlined class="body-2 pa-5 pl-2">
                          <v-row dense >
                            <v-col cols="12">
                              <v-row dense>
                                <v-col cols="10" class="subtitle-2 font-weight-bold ma-0 pa-0" >
                                  <v-row dense>
                                    <v-col cols="1">
                                      <v-icon v-if="each.grade == 1" color="green">looks_one</v-icon>
                                      <v-icon v-if="each.grade == 2" color="blue">looks_two</v-icon>
                                      <v-icon v-if="each.grade == 3" color="orange">local_hospital</v-icon>
                                    </v-col>
                                    <v-col cols="11">
                                      {{ each.name }}
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" >
                                  섭취량 : {{ each.amount }}
                                </v-col>
                                <v-col cols="12" >
                                  코멘트 : {{ each.comment }}
                                </v-col>
                                <v-col cols="12" v-if="each.target" >
                                  대상 : {{ each.target }}
                                </v-col>
                                <v-col cols="12" v-if="each.usage" >
                                  섭취방법 : {{ each.usage }}
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col cols="12" class="caption">
                                  <v-expansion-panels>
                                    <v-expansion-panel>
                                      <v-expansion-panel-header class="font-weight-bold ma-0 pa-0">추천 상품</v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                        <v-row dense class="mt-5">
                                          <v-col md="6" cols="6" v-for="(link, i) in each.link" :key="i" class=" ma-0 pa-0 mb-3">

                                            <v-card flat class="pl-2" :href="link.link" target="_blank">
                                              <v-row no-gutters>
                                                <v-col cols="8" class=" ma-0 pa-0">
                                                  <div v-if="link.type == 1" class="icon">
                                                    🍀
                                                  </div>
                                                  <div v-if="link.type == 2" class="icon">
                                                    🚀
                                                  </div>
                                                  <div v-if="link.type == 3" class="icon">
                                                    🎉
                                                  </div>
                                                </v-col>
                                                <v-col cols="12" class="body-2">
                                                  {{ link.name }}
                                                </v-col>
                                              </v-row>
                                            </v-card>

                                          </v-col>
                                        </v-row>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                  </v-expansion-panels>
                                </v-col>
                              </v-row>

                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>

                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card>

        <v-card flat class="pa-4 ma-3">
          <v-col cols="12" class="body-2">
            해당 링크를 통해 구매시 동면중에게 도움이 됩니다.
          </v-col>
        </v-card>

      </v-col>
    </v-row>
  </div>
</template>


<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import api_conf from '../../config/config.json';

import { mapGetters } from 'vuex'


export default {
  props: {
    msg: String
  },
  data: () => {
    return {
      supplement_tab: "",
      list: {},
      imgs: [
        require('@/assets/iherb.png'),
        require('@/assets/coupang.png'),
        require('@/assets/myprotein.png')
      ],
      grades: [
        { name: 'A급', grade: 1 },
        { name: 'B급', grade: 2 },
        { name: '번외성분', grade: 3 },
      ],
      types: [
        { name: 'iHerb', shop: 1 },
        { name: '쿠팡', shop: 2 },
        { name: 'Myprotein', shop: 3 },
      ],
      categories: [
        { name: '초보자', category: 0 },
        { name: '숙련자', category: 1 },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
      lvl: "lvl",
      mobile: "mobile",
    })
  },
  mounted: async function() {
    await this.reload_info();
  },
  methods: {
    reload_info: async function() {
      if ( firebase.auth().currentUser ) {
        // 이전 정보가 있는지 확인
        const id_token = await firebase.auth().currentUser.getIdToken();
        const categories_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement/recommended/category/', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        for ( const each of categories_.data ) {
          this.categories.push({
            name: each.name,
            order: each.order,
            _id: each._id,
            category: each._id,
            children: 0,
          });
        }
        const supplements_ = await axios.get(
          api_conf.protocol+'://'+api_conf.server+':'+api_conf.port+'/supplement/recommended/', {
          params: { email: this.user.data.email },
          headers: {'id_token': id_token},
        });

        if (supplements_.data.length>0) {
          //this.list = supplements_.data;
          for ( let each of supplements_.data ) {
            if ( this.list[each.category] == undefined ) {
              // this.list.splice(each.category, 1, []);
              this.$set(this.list, each.category, []);
            }
            this.list[each.category].push(each);
          }
        }

        this.loading = false;
      } else {
        setTimeout(() => {
          this.reload_info();
        }, 500);
      }
    },
  }
}
</script>

<style scoped>

.v-data-table td{
  padding:0 !important;

}

.v-expansion-panel::before {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.v-expansion-panel > .v-expansion-panel-header {
    min-height: 0px;
}

.icon {
  font-size: 24px;
  margin-bottom: 10px;
}

</style>
