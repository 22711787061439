<template>
  <div>
    <div v-if="!userloading && user.login">

      <v-tabs 
        v-model="tab" 
        :vertical="!mobile" 
        show-arrows 
        class="fixed" background-color="#141E30" color="white" style="min-height:calc(100vh - 48px)">
        <v-tab key="supplement" to="#supplement">보충제</v-tab>
        <v-tab key="chicken" to="#chicken">닭가슴살</v-tab>

        <v-tabs-items touchless v-model="tab">
          <v-tab-item
            id="supplement"
            key="supplement"
            :transition="false"
            :reverse-transition="false">
            <Supplement />
          </v-tab-item>

          <v-tab-item
            id="chicken"
            key="chicken"
            :transition="false"
            :reverse-transition="false">
            <Chicken />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

    </div>
    <div v-if="!userloading && !user.login" >
      <NeedLogin />
    </div>
    <div v-if="userloading">
      <UserLoading />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Chicken from './Supplement/Chicken';
import Supplement from './Supplement/Supplement';

import NeedLogin from './User/NeedLogin';
import UserLoading from './User/UserLoading';

export default {
  components: {
    NeedLogin,
    Chicken,
    Supplement,
    UserLoading,
  },
  props: {
    msg: String
  },
  computed: {
    ...mapGetters({
      user: "user",
      mobile: "mobile",
      userloading: "userloading",

    }),
  },
  data() {
    return {
      tab: "supplement",
    }
  },

}
</script>

<style scoped>

</style>